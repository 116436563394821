<template>
  <div class="d-flex flex-column flex-root">
    <div class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signin-on" id="kt_login">
    <!--begin::Aside-->
    <div class="login-aside d-flex flex-column flex-row-auto" style="background-color: #b43327;">
      <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15 text-center">
        <a href="#" class="flex-column-auto">
          <img src="media/logos/logo.png" style="width: 450px;margin-top: 83px;"/>
        </a>
        <div class="flex-column-fluid d-flex flex-column justify-content-center">
          <h3 class="font-size-h1 mt-15 mb-5 text-white">
            Bienvenue chez L'Assureur Français
          </h3>
        </div>
      </div>
      <div class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center" :style="{ backgroundImage: `url(${backgroundImage})` }"></div>
    </div>
    <!--begin::Aside-->
    <!--begin::Content-->
    <div class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
      <div class="d-flex flex-column-fluid flex-center">
        <div class="login-form login-signin">
          <form class="form" novalidate="novalidate" id="kt_login_signin_form">
            <div class="pb-13 pt-lg-0 pt-5">
              <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">S'identifier</h3>
              <span class="text-muted font-weight-bold font-size-h4">Entrez votre email et votre mot de passe</span>
            </div>
            <div class="form-group">
              <label class="font-size-h6 font-weight-bolder text-dark">Email</label>
              <div id="example-input-group-1" label="" label-for="example-input-1">
                <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text" name="email" ref="email" v-model="user.email" />
              </div>
            </div>
            <div class="form-group">
              <div class="d-flex justify-content-between mt-n5">
                <label class="font-size-h6 font-weight-bolder text-dark pt-5">Mot de passe</label>
              </div>
              <div id="example-input-group-2" label="" label-for="example-input-2">
                <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="password" name="password" ref="password" v-model="user.password" autocomplete="off" />
              </div>
            </div>
            <div class="pb-lg-0 pb-5 text-center">
              <b-button variant="primary" class="font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3" @click="login()">
                Connexion
                <i v-if="loader" class="fas fa-circle-notch fa-spin fa-2x ml-3"></i>
              </b-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
export default {
  name: "login-1",
  data() {
    return {
      loader:false,
      user: {
        email: null,
        password: null
      }
    };
  },
  computed: {
    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/car.png"
      );
    }
  },
  mounted() {
  },
  methods: {
    login(){
      if(this.loader === true){
        return false;
      }
      this.loader = true;
      this.$store.back.ajax('/authorization/create', this.user, (res) => {
        if(res.status === true){
          this.$store.back.saveToken(res.data.token)
          this.$store.back.saveUser(res.data)
          this.$router.push({path:'/lead/liste'})
        }
        this.loader = false;
      })
    }
  }
};
</script>
